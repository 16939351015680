<template>
  <li class="interactive">
    <a
      href="">  {{interactive_description}} </a><span> {{interactive_rate}} puan</span>
  </li>


</template>

<script>
export default {
  name: "Interactive",
  props:{
    interactive_description:
        {
      required:true,
    }
    ,
    interactive_rate:
        {
      required:true,
    }


  }
}
</script>

