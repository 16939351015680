<template>

<a @click="$emit('onClickTag', id)" ><span class="mr-2 bg-light p-2 mt-1"   :style="{backgroundColor: tagColor + ' !important'}"> #{{title}}</span></a>



</template>

<script>
export default {
  name: "Tags",
  props:{
    id: {
      required: true,
    },
    tagColor: {
      required: false,
    },
    title: {
      required: true,
    },

  },


}
</script>

