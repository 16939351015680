<template>
  <div class="course-layouts">
    <div class="course-content bg-dark">
      <div class="course-header">
        <a href="#" class="btn-back" uk-toggle="target: .course-layouts; cls: course-sidebar-collapse">
          <i class="icon-feather-chevron-left"></i>
        </a>
        <h3 class="text-white"> {{item.name}} </h3>
        <div>
          <span>Okuma</span>
          <a href="#">
            <i class="icon-feather-help-circle btns"></i> </a>
          <div uk-drop="pos: bottom-right;mode : click">
            <div class="uk-card-default p-4">
              <h4>Elementum tellus id mauris faucibuss soluta nobis </h4>
              <p class="mt-2 mb-0"></p>
            </div>
          </div>
          <a hred="#">
            <i class="icon-feather-more-vertical btns"></i>
          </a>
          <div class="dropdown-option-nav " uk-dropdown="pos: bottom-right ;mode : click">
            <ul>
              <li><a href="#">
                <i class="icon-feather-bookmark"></i>
                Add To Bookmarks</a></li>
              <li><a href="#">
                <i class="icon-feather-share-2"></i>
                Share With Friend </a></li>
              <li>
                <a href="#" id="night-mode" class="btn-night-mode">
                  <i class="icon-line-awesome-lightbulb-o"></i> Night mode
                  <label class="btn-night-mode-switch">
                    <div class="uk-switch-button"></div>
                  </label>
                </a>
              </li>
            </ul>
          </div>
        </div>
      </div>
      <div class="course-content-inner">
        <ul id="learning-slider" class="uk-switcher">
<!--          <li>-->
<!--            <div class="container">-->
<!--              <div class="row p-4">-->
<!--                <div class="col-md-6">-->
<!--                  <img style="border-radius:10px"   :src="item.cover_image_link" class="img-fluid" alt="">-->
<!--                </div>-->
<!--                <div class="col-md-6">-->
<!--                  <h2 style="color:#fff;">{{item.name}} saDA</h2>-->
<!--                  <p style="color:#fff;">Lorem ipsum dolor sit amet consectetur adipisicing elit. Quibusdam accusamus voluptas odio aperiam, repellat est ullam quas fugiat laudantium perferendis, ipsa ex, itaque qui velit. Laudantium deserunt voluptate enim eligendi.</p>-->
<!--                  <ul>-->
<!--                    <li style="color:rgb(240, 193, 193)">-->
<!--                      {{item.description}}-->
<!--                    </li>-->
<!--                       </ul>-->
<!--                </div>-->
<!--              </div>-->
<!--            </div>-->

<!--          </li>-->
          <li>
            <div class="video-responsive" >
              <!-- <iframe src="https://video.anzera.com.tr/anzera/isg-az-tehlikeli-a-sinif/isg_18_03.mp4"
                  frameborder="0" allowfullscreen></iframe> -->
<!--              <video controls src="https://video.anzera.com.tr/anzera/isg-az-tehlikeli-a-sinif/isg_18_03.mp4"></video>-->
                            <iframe v-if="currentContent" :src="currentContent.link" frameborder="0" allowfullscreen uk-responsive></iframe>

            </div>
          </li>
          <li>
            <div class="video-responsive">
              <video controls src="https://video.anzera.com.tr/anzera/isg-az-tehlikeli-a-sinif/isg_18_04.mp4"></video>
            </div>
          </li>
          <li>
            <div class="video-responsive">

              <video controls src="https://video.anzera.com.tr/anzera/isg-az-tehlikeli-a-sinif/isg_18_05.mp4"></video>
            </div>
          </li>
          <li>
            <div class="iframe-responsive">
              <iframe src="https://demo.anzera.com.tr/dowaksa/isg/" title="W3Schools Free Online Web Tutorials"></iframe>
            </div>
          </li>
          <li>
            <div class="quiz-responsive">
              <div class="container">
                <div class="row">
                  <div class="col-md-6 offset-md-3  text-center">
                    <div style="margin-top:5rem;" class="avatar-parent-child mb-4">
                      <img style="border:3px solid #ccc;z-index:99;" alt="Erdem Kanber" :src="mediaBase +'/images/avatars/avatar-2.jpg'"
                           class="avatar  rounded-circle avatar-lg">
                      <img style="border: 3px solid #ccc;margin-left:-1rem;" alt="" :src="mediaBase +'/images/avatars/avatar_duel.jpg'"
                           class="avatar rounded-circle avatar-lg">
                      <span style="color:#fff;display:block;font-size:.875rem;margin-top:.5rem;"><b>Sen</b> vs <b>?</b></span>
                    </div>
                    <h2 class="quiz__question mb-3">Düelloya Hoşgeldin!</h2>
                    <p class="lead" style="color:#fff;line-height:1.2">Başlamadan önce bir arkadaşını seçerek ona meydan okumalısın. Düello davetin kabul olduktan sonra soruları cevaplamaya başlayabilirsin.<br>Başarılar!</p>
                    <div class="submit__container" href="#modal-overflow" uk-toggle>
                      <a href="#" class="btn btn-success"><i class="uil-user-plus"></i> ARKADAŞINI SEÇ</a>
                    </div>
                  </div>
                </div>
              </div>
              <div id="modal-overflow" uk-modal>
                <div class="uk-modal-dialog"> <button class="uk-modal-close-default"
                                                      type="button" uk-close></button>
                  <div class="uk-modal-header">
                    <h3 class="uk-modal-title">Düello için arkadaşını seç</h3>
                  </div>
                  <div class="uk-modal-body" uk-overflow-auto>

                    <div class="uk-grid-small uk-flex-middle position-relative" uk-grid>
                      <div class="uk-width-auto position-relative">
                        <img class="rounded-circle avatar-sm"
                             :src=" mediaBase +   '/images/avatars/avatar-1.jpg'">
                      </div>
                      <div class="uk-width-expand">
                        <p class="uk-text-truncate mb-0">
                          Hande Güler</p>
                      </div>
                      <div>
                        <!-- <a href="" class="btn btn-primary"><i class="uil-bolt-alt"></i> Düello Gönder</a> -->
                        <p><b><i class="uil-stopwatch"></i> Davet Gönderildi. Onay bekleniyor</b></p>
                        <a href="btn%20btn-outline%20btn-sm.html"><i class="uil-times"></i> Vazgeç</a>
                      </div>
                    </div>
                    <hr>
                    <div class="uk-grid-small uk-flex-middle position-relative" uk-grid>
                      <div class="uk-width-auto position-relative">
                        <img class="rounded-circle avatar-sm"
                             src="/images/avatars/avatar-2.jpg">
                      </div>
                      <div class="uk-width-expand">
                        <p class="uk-text-truncate mb-0">
                          Ahmet Güngördü</p>
                      </div>
                      <div>
                        <a href="#" class="btn btn-primary"><i class="uil-bolt-alt"></i> Düello Gönder</a>
                      </div>
                    </div>
                    <hr>
                    <div class="uk-grid-small uk-flex-middle position-relative" uk-grid>
                      <div class="uk-width-auto position-relative">
                        <img class="rounded-circle avatar-sm"
                             :src="mediaBase  + '/images/avatars/avatar-1.jpg'">
                      </div>
                      <div class="uk-width-expand">
                        <p class="uk-text-truncate mb-0">
                          Sibel Morgül</p>
                      </div>
                      <div>
                        <a href="#" class="btn btn-primary"><i class="uil-bolt-alt"></i> Düello Gönder</a>
                      </div>
                    </div>
                    <hr>
                    <div class="uk-grid-small uk-flex-middle position-relative" uk-grid>
                      <div class="uk-width-auto position-relative">
                        <img class="rounded-circle avatar-sm"
                             src="/images/avatars/avatar-2.jpg">
                      </div>
                      <div class="uk-width-expand">
                        <p class="uk-text-truncate mb-0">
                          Ahmet Güngördü</p>
                      </div>
                      <div>
                        <a href="#" class="btn btn-primary"><i class="uil-bolt-alt"></i> Düello Gönder</a>
                      </div>
                    </div>
                    <hr>
                    <div class="uk-grid-small uk-flex-middle position-relative" uk-grid>
                      <div class="uk-width-auto position-relative">
                        <img class="rounded-circle avatar-sm"
                             :src="mediaBase + '/images/avatars/avatar-1.jpg'">
                      </div>
                      <div class="uk-width-expand">
                        <p class="uk-text-truncate mb-0">
                          Sibel Morgül</p>
                      </div>
                      <div>
                        <a href="#" class="btn btn-primary"><i class="uil-bolt-alt"></i> Düello Gönder</a>
                      </div>
                    </div>
                    <hr>
                    <div class="uk-grid-small uk-flex-middle position-relative" uk-grid>
                      <div class="uk-width-auto position-relative">
                        <img class="rounded-circle avatar-sm"
                             src="/images/avatars/avatar-2.jpg">
                      </div>
                      <div class="uk-width-expand">
                        <p class="uk-text-truncate mb-0">
                          Ahmet Güngördü</p>
                      </div>
                      <div>
                        <a href="#" class="btn btn-primary"><i class="uil-bolt-alt"></i> Düello Gönder</a>
                      </div>
                    </div>
                    <hr>
                    <div class="uk-grid-small uk-flex-middle position-relative" uk-grid>
                      <div class="uk-width-auto position-relative">
                        <img class="rounded-circle avatar-sm"
                             :src="mediaBase + '/images/avatars/avatar-1.jpg'">
                      </div>
                      <div class="uk-width-expand">
                        <p class="uk-text-truncate mb-0">
                          Sibel Morgül</p>
                      </div>
                      <div>
                        <a href="#" class="btn btn-primary"><i class="uil-bolt-alt"></i> Düello Gönder</a>
                      </div>
                    </div>
                    <hr>

                  </div>
                </div>
              </div>

            </div>
          </li>
          <li>
            <div class="quiz-responsive duel">
              <div class="container">
                <div class="quiz__inner">
                  <div data-question="1" class="quiz__step--1 quiz__step--current quiz__step">
                    <div class="avatar-parent-child mb-4">
                      <img  style="border:3px solid #62d76b;z-index:99;" alt="Erdem Kanber" :src="  mediaBase + '/images/avatars/avatar-2.jpg'"
                            class="avatar  rounded-circle avatar-lg">
                      <img style="border: 3px solid #ccc;margin-left:-1rem;" alt="Hande Kanber" :src=" mediaBase + '/images/avatars/avatar-1.jpg'"
                           class="avatar  rounded-circle avatar-lg">
                      <span style="color:#fff;display:block;font-size:.875rem;margin-top:.5rem;"><b>Sen</b> vs <b>Hande Güler</b></span>
                    </div>
                    <h1 class="quiz__question"></h1>
                    <div class="answer">
                      <input data-char="65" class="answer__input" type="radio"
                             id="option1" name="smoke" value="Yes">
                      <label class="answer__label" style="background-color:#5dffedee" for="option1"></label>
                    </div>
                    <div class="answer">
                      <input data-char="66" class="answer__input" type="radio"
                             id="option2" name="smoke" value="No">
                      <label class="answer__label" style="background-color:#74bae8ee" for="option2"></label>
                    </div>
                    <div class="answer">
                      <input data-char="66" class="answer__input" type="radio"
                             id="option3" name="smoke" value="No">
                      <label class="answer__label" style="background-color:#f5c835ee
                                                    " for="option3"></label>
                    </div>
                    <div class="answer">
                      <input data-char="66" class="answer__input" type="radio"
                             id="option4" name="smoke" value="No">
                      <label class="answer__label" style="background-color:#d198ffee" for="option4"></label>
                    </div>

                  </div>

                  <div data-question="2" class="quiz__step--2 quiz__step">
                    <div class="avatar-parent-child mb-4">
                      <img style="border:3px solid #62d76b;z-index:99;" alt="Erdem Kanber" src="/images/avatars/avatar-2.jpg"
                           class="avatar rounded-circle avatar-lg">
                      <img style="border: 3px solid #ccc;margin-left:-1rem;" alt="Hande Kanber" :src="mediaBase +'/images/avatars/avatar-1.jpg'"
                           class="avatar  rounded-circle avatar-lg">
                      <span style="color:#fff;display:block;font-size:.875rem;margin-top:.5rem;"><b>Sen</b> vs <b>Hande Güler</b></span>
                    </div>

                    <h1 class="quiz__question"></h1>
                    <div class="answer">
                      <input data-char="65" class="answer__input" type="radio"
                             id="option5" name="smoke" value="Yes">
                      <label class="answer__label" style="background-color:#5dffedee" for="option5"></label>
                    </div>
                    <div class="answer">
                      <input data-char="66" class="answer__input" type="radio"
                             id="option6" name="smoke" value="No">
                      <label class="answer__label" style="background-color:#74bae8ee" for="option6"></label>
                    </div>
                    <div class="answer">
                      <input data-char="66" class="answer__input" type="radio"
                             id="option7" name="smoke" value="No">
                      <label class="answer__label" style="background-color:#f5c835ee
                                                    " for="option7"></label>
                    </div>
                  </div>
                  <div data-question="3" class="quiz__step--3 quiz__step">
                    <div class="avatar-parent-child mb-4">
                      <img style="border:3px solid #62d76b;z-index:99;" alt="Erdem Kanber" :src=" mediaBase + ' /images/avatars/avatar-2.jpg'"
                           class="avatar  rounded-circle avatar-lg">
                      <img style="border: 3px solid #ccc;margin-left:-1rem;" alt="Hande Kanber" :src="mediaBase + '/images/avatars/avatar-1.jpg'"
                           class="avatar  rounded-circle avatar-lg">
                      <span style="color:#fff;display:block;font-size:.875rem;margin-top:.5rem;"><b>Sen</b> vs <b>Hande Güler</b></span>
                    </div>

                    <h1 class="quiz__question"></h1>
                    <div class="answer">
                      <input data-char="65" class="answer__input" type="radio"
                             id="option8" name="smoke" value="Yes">
                      <label class="answer__label" style="background-color:#5dffedee" for="option8"></label>
                    </div>
                    <div class="answer">
                      <input data-char="66" class="answer__input" type="radio"
                             id="option9" name="smoke" value="No">
                      <label class="answer__label" style="background-color:#74bae8ee" for="option9"></label>
                    </div>
                    <div class="answer">
                      <input data-char="66" class="answer__input" type="radio"
                             id="option10" name="smoke" value="No">
                      <label class="answer__label" style="background-color:#f5c835ee
                                                    " for="option10"></label>
                    </div>
                    <div class="answer">
                      <input data-char="66" class="answer__input" type="radio"
                             id="option11" name="smoke" value="No">
                      <label class="answer__label" style="background-color:#d198ffee" for="option11"></label>
                    </div>
                  </div>
                  <div data-question="4" class="quiz__step--4 quiz__step quiz__summary">
                    <div class="avatar-parent-child mb-4">
                      <img style="border:3px solid #ccc;z-index:99;" alt="Erdem Kanber" src=" +'/images/avatars/avatar-2.jpg"
                           class="avatar  rounded-circle avatar-lg">
                      <img style="border: 3px solid #ccc;margin-left:-1rem;" alt="Hande Kanber" :src="mediaBase +'/images/avatars/avatar-1.jpg'"
                           class="avatar  rounded-circle avatar-lg">
                      <span style="color:#fff;display:block;font-size:.875rem;margin-top:.5rem;"><b>Sen</b> vs <b>Hande Güler</b></span>
                    </div>
                    <h3 class="quiz__question">Soruları Tamamladın. Sorulara geri dönerek cevaplarını tekrar kontrol edebilirsin veya "Düelloyu Tamamla" butonuna tıklayarak bitirebilirsin. </h3>
                    <div class="submit__container">
                      <a href="#" class="submit">Düelloyu Tamamla</a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </li>
        </ul>
      </div>
    </div>
    <!-- course sidebar -->
    <div class="course-sidebar">
      <div class="course-sidebar-title">
        <h3> {{item.name}}</h3>
      </div>
      <div class="course-sidebar-container" data-simplebar>
        <ul class="course-video-list-section" uk-accordion>
<!--          <li class="">-->
<!--            <a class="uk-accordion-title" href="#"> Genel Konular </a>-->
<!--            <div class="uk-accordion-content">-->
<!--              &lt;!&ndash; course-video-list &ndash;&gt;-->
<!--              <ul class="course-video-list highlight-watched">-->
<!--                <li class="watched"> <a href="#"> {{item.name}}  </a> </li>-->
<!--                <li class="watched"> <a href="#"> Sağlık ve Güvenlik Kültürü-->
<!--                </a>-->
<!--                </li>-->
<!--                <li class="watched"> <a href="#"> Kaza  </a></li>-->
<!--                <li class="watched"> <a href="#"> İş Kazası</a></li>-->
<!--                <li class="watched"> <a href="#"> Sıra Sende </a> </li>-->

<!--              </ul>-->
<!--            </div>-->
<!--          </li>-->
          <template v-for="section in item.training_section">
          <li class="uk-open">
            <a class="uk-accordion-title" href="#"> {{section.name}} </a>
            <div class="uk-accordion-content">
              <!-- course-video-list -->
              <ul class="course-video-list highlight-watched"
                  uk-switcher=" connect: #learning-slider  ; animation: uk-animation-slide-right-small, uk-animation-slide-left-medium">
                <template v-for="content in section.training_content">
                <li> <a @click.prevent="startVideo(content)"> {{content.name}}</a> </li>
               </template>
              </ul>
            </div>
          </li>
          </template>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
import DashboardLayout from "@/view/layouts/DashboardLayout";
import Section from "@/view/components/educationDetail/Section";
import Tags from "@/view/components/search/Tags";
import Comments from "@/view/components/educationDetail/Comments";
import Read from "@/view/components/educationDetail/Read";
import Watch from "@/view/components/educationDetail/Watch";
import Quiz from "@/view/components/educationDetail/Quiz";
import Interactive from "@/view/components/educationDetail/Interactive";
import Footer from "@/view/layouts/Footer";
import {SET_MESSAGE, SAVE_MESSAGE} from "@/core/services/store/education/educationDetail.module";
import {mapGetters, mapMutations} from "vuex";
import {LOGOUT} from "@/core/services/store/auth.module";
import $ from 'jquery';
import {CURRENT_ITEM, GET_ITEM_DETAIL_BY_ID} from "@/core/services/store/REST.module";
export default {
  name: "EducationPlayer",
  components: {
    DashboardLayout,
    Section,
    Tags,
    Comments,
    Read,
    Watch,
    Quiz,
    Interactive,
    Footer
  },
  data() {
    return {
      restUrl: 'api/training/',
      currentContent: null
    };
  },
  computed: {
    ...mapGetters({
      item: CURRENT_ITEM
    }),
    messageProxy: {
      set(value) {
        this.setMessage(value);
      },
      get() {
        return this.message;
      }
    },
  },
  mounted() {
    let trainingId = this.$route.params.id;
    if (trainingId) {
      this.$store.dispatch(GET_ITEM_DETAIL_BY_ID, {
        url: this.restUrl + trainingId,
        acceptPromise: false,

      });
    } else {
      this.$router.go(-1);
    }
  },
  methods: {
    ...mapMutations({
      setMessage: SET_MESSAGE,
    }),
    saveMessage() {
      this.$store.dispatch(SAVE_MESSAGE);
    },
    startVideo(content) {
      this.currentContent = content;
    }
  }
}
</script>

<style scoped>

</style>
