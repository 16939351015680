<template>
  <li class="read completed">

    <a
      href="">
    {{read_description}}
    dolor</a><span><i class="uil-check-circle"></i> {{read_rate}} puan
                        </span></li>


</template>

<script>
export default {
  name: "Read",
  props:{
    read_description:
        {
      required:true,
    }
    ,
    read_rate:
        {
      required:true,
    }


  }
}
</script>

