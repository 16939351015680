<template>
  <li :class="className">
    <a class="uk-accordion-title" href="#"> Bölüm {{number}}</a>
    <div class="uk-accordion-content">

      <!-- course-video-list -->
      <ul class="course-curriculum-list">
        <li class="read completed"><input type="checkbox" name="" id=""> <a
            href="">Lorem ipsum dolor sit amet consectetur adipisicing elit
          dolor</a><span><i class="uil-check-circle"></i> 10 puan
                                                    </span>
        <li class="watch completed"><input type="checkbox" name="" id=""> <a
            href="">Consectetur adipisicing elit</a> <span><i
            class="uil-check-circle"></i> 5 puan</span> </li>
        <li class="watch completed"><input type="checkbox" name="" id=""> <a
            href="">Lorem ipsum dolor sit amet </a><span><i
            class="uil-check-circle"></i> 5 puan</span></li>
        <li class="quiz"><input type="checkbox" name="" id=""> <a href="">Lorem
          Ipsum </a><span> 15 puan</span> </li>
        <li class="interactive"><input type="checkbox" name="" id=""> <a
            href="">Adipisicing elit adipisicing </a><span> 20 puan</span>
        </li>
      </ul>

    </div>
  </li>

</template>

<script>
export default {
  name: "Section",
  props:{
    number: {
      required: true,
    },
    className: {
      required: true,
    },

  }
}
</script>

