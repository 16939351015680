<template>
  <li class="quiz">

    <a href="">{{quiz_description}}} </a><span> {{quiz_rate}} puan</span> </li>

</template>

<script>
export default {
  name: "Read",
  props:{
    quiz_description:
        {
      required:true,
    }
    ,
    quiz_rate:
        {
      required:true,
    }


  }
}
</script>

