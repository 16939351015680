<template>
  <li id="Comments">
    <div class="comments-avatar"><img :src="image" alt="">
    </div>
    <div class="comment-content">
      <div class="comment-by pb-0">{{ name }} <span style="font-size:.6rem;line-height:1em">{{ title }}</span>
      </div>
      <div class="row m-0">
        <p class="mt-0 pt-0">{{ comment }} </p>
        <p class="mt-0 pt-0 ml-2" v-if="status_id === 1">
          <strong>(Yönetici Onayına Gönderildi)</strong>
        </p>
      </div>
    </div>
  </li>
</template>

<script>
export default {
  name: "Comments",
  props: {
    image: {
      required: true,
    },
    name: {
      required: true,
    },
    comment: {
      required: true,
    },
    title: {
      required: true,
    },
    status_id: {
      required: false,
    }
  }
}
</script>

