<template>
  <li class="watch completed">

    <a
      href="#" @click.prevent="$emit('linkClick')"> {{watch_description}}</a> <span><i
      class="uil-check-circle"></i> {{watch_rate}} puan</span> </li>


</template>

<script>
export default {
  name: "Watch",
  props:{
    watch_description:
        {
      required:true,
    }
    ,
    watch_rate:
        {
      required:true,
    }


  }
}
</script>

